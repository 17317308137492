// Setting1.js
import React, { useState } from 'react';
import './Setting1.css';

const Setting1 = () => {
    const [wordList, setWordList] = useState('PTE');
    const [favList, setFavList] = useState('PTE');

    return (
        <div className="setting-item">
            <div className="setting-row">
                <label>单词库</label>
                <select value={wordList} onChange={(e) => setWordList(e.target.value)}>
                    <option value="PTE">PTE</option>
                    <option value="雅思">雅思</option>
                    <option value="四六级">四六级</option>
                </select>
            </div>
            <div className="setting-row">
                <label>收藏夹</label>
                <select value={favList} onChange={(e) => setFavList(e.target.value)}>
                    <option value="PTE">PTE</option>
                    <option value="雅思">雅思</option>
                    <option value="四六级">四六级</option>
                </select>
            </div>
        </div>
    );
};

export default Setting1;
