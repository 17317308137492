// Setting2.js
import React, { useState } from 'react';
import './Setting2.css';

const Setting2 = () => {
    const [submit, setSubmit] = useState(false);
    const [next, setNext] = useState(false);
    const [favorite, setFavorite] = useState(false);

    return (
        <div className="setting-item">
            <div className="setting-row">
                <label>提交</label>
                <input type="checkbox" checked={submit} onChange={() => setSubmit(!submit)} />
            </div>
            <div className="setting-row">
                <label>下一个</label>
                <input type="checkbox" checked={next} onChange={() => setNext(!next)} />
            </div>
            <div className="setting-row">
                <label>收藏</label>
                <input type="checkbox" checked={favorite} onChange={() => setFavorite(!favorite)} />
            </div>
        </div>
    );
};

export default Setting2;
