import React, { useState } from 'react';
import styles from './AddWordComponent.module.css'; // 使用 CSS 模块
const hostApi = 'http://107.174.246.6:3001/api/'; // 后端 API 地址
const AddWordComponent = () => {
  const [activeTab, setActiveTab] = useState('import');
  const [libraryName, setLibraryName] = useState('');
  const [word, setWord] = useState('');
  const [file, setFile] = useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLibraryNameChange = (e) => {
    setLibraryName(e.target.value);
  };

  const handleWordChange = (e) => {
    setWord(e.target.value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleAddWord = async () => {
    if (libraryName && word) {
      const requestBody = { library_name: libraryName, word: word };
      console.log('Sending request with body:', requestBody); // 打印请求体
      const response = await fetch(hostApi + 'addWord', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      console.log('Response:', response); // 打印响应
      if (response.ok) {
        alert('单词添加成功');
        setWord('');
      } else {
        const result = await response.json();
        alert(result.error || '添加单词失败');
      }
    } else {
      alert('请填写词库和单词');
    }
  };

  const handleImportWords = async () => {
    if (libraryName && file) {
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const content = e.target.result;
        const words = content.split(/\r?\n/).filter(Boolean); // 按行分割并去除空行
        const requestBody = { library_name: libraryName, words: words };
        console.log('Sending request with body:', requestBody); // 打印请求体
        const response = await fetch(hostApi + 'importWords', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
        console.log('Response:', response); // 打印响应
        if (response.ok) {
          alert('单词导入成功');
          setFile(null);
        } else {
          const result = await response.json();
          alert(result.error || '导入单词失败');
        }
      };
      fileReader.readAsText(file);
    } else {
      alert('请填写词库并选择文件');
    }
  };

  return (
    <div className={styles.container}>
      <h3>添加单词</h3>
      <div className={styles.tabs}>
        <div
          onClick={() => handleTabClick('import')}
          className={`${styles.tab} ${activeTab === 'import' ? styles.active : ''}`}
        >
          导入单词
        </div>
        <div
          onClick={() => handleTabClick('add')}
          className={`${styles.tab} ${activeTab === 'add' ? styles.active : ''}`}
        >
          新增单词
        </div>
      </div>
      <div className={styles.content}>
        <input
          type="text"
          placeholder="请填写词库"
          className={styles.libraryInput}
          value={libraryName}
          onChange={handleLibraryNameChange}
        />
        {activeTab === 'import' ? (
          <div>
            <input type="file" className={styles.fileInput} onChange={handleFileChange} />
            <button className={styles.uploadButton} onClick={handleImportWords}>上传文件</button>
          </div>
        ) : (
          <div>
            <input
              type="text"
              placeholder="输入单词"
              className={styles.textInput}
              value={word}
              onChange={handleWordChange}
            />
            <button className={styles.addButton} onClick={handleAddWord}>Add</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddWordComponent;
