import React, { useState, useRef, useEffect, useCallback } from 'react';
import './App.css';
import SettingsModal from './SettingsModal';
import ShowMore from './ShowMore';
const hostApi = 'http://107.174.246.6:3001/api/';
const App = () => {
    const [currentWord, setCurrentWord] = useState({});
    const [nextWord, setNextWord] = useState({});
    const [result, setResult] = useState('');
    const [userInput, setUserInput] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [favorite, setFavorite] = useState([]);
    const [showSettings, setShowSettings] = useState(false);
    const [showSettingsButton, setShowSettingsButton] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [message, setMessage] = useState('');
    const [libraryName, setLibraryName] = useState('');
    const [libraryConfirmed, setLibraryConfirmed] = useState(false);
    const [review, setIsReview] = useState(false);
    const [libraries, setLibraries] = useState([]);
    const inputRef = useRef(null);
    const audioRef = useRef(null);

    const fetchWordDetails = useCallback(async () => {
        try {
            const response = await fetch(`${hostApi}word?library_name=${libraryName}&is_review=${review}`);
            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                console.error('Error fetching word details:', response.statusText);
                setResult('Failed to fetch word details.');
                return { word: {} };
            }
        } catch (error) {
            console.error('Error fetching word details:', error);
            setResult('Network error.');
            return { word: {} };
        }
    }, [libraryName, review]);

    const fetchLibraries = useCallback(async () => {
        try {
            const response = await fetch(`${hostApi}libraries`);
            if (response.ok) {
                const data = await response.json();
                setLibraries(data.libraries);
            } else {
                console.error('Error fetching libraries:', response.statusText);
                setMessage('Failed to fetch libraries.');
            }
        } catch (error) {
            console.error('Error fetching libraries:', error);
            setMessage('Network error.');
        }
    }, []);

    useEffect(() => {
        fetchLibraries();
    }, [fetchLibraries]);

    const loadNextWord = useCallback(async () => {
        const data = await fetchWordDetails();
        return data.word;
    }, [fetchWordDetails]);

    useEffect(() => {
        const initializeWords = async () => {
            if (libraryConfirmed) {
                const currentWordData = await loadNextWord();
                const nextWordData = await loadNextWord();
                setCurrentWord(currentWordData);
                setNextWord(nextWordData);
            }
        };

        initializeWords();
    }, [loadNextWord, libraryConfirmed]);

    const advanceToNextWord = useCallback(async () => {
        setMessage('');
        setResult('');
        setUserInput('');
        setHasSubmitted(false);

        const newCurrentWord = nextWord;
        const newNextWord = await loadNextWord();

        setCurrentWord(newCurrentWord);
        setNextWord(newNextWord);
    }, [nextWord, loadNextWord]);

    const checkSpelling = useCallback(() => {
        if (userInput.trim() === currentWord.word) {
            advanceToNextWord();
        } else {
            setResult(currentWord.word);
            setHasSubmitted(true);
        }
    }, [userInput, currentWord, advanceToNextWord]);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
            checkSpelling();
        } else if (event.key === 'ArrowRight') {
            advanceToNextWord();
        }
    }, [checkSpelling, advanceToNextWord]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setUserInput(inputValue);
        if (hasSubmitted) {
            setUserInput('');
            setHasSubmitted(false);
            setResult('');
        }
    };

    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const toggleFavorite = async () => {
        try {
            const response = await fetch(`${hostApi}favorites`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ word: currentWord.word, library_name: libraryName, is_favorite: favorite !== currentWord.word })
            });
            const result = await response.json();
            if (result.success) {
                setFavorite(prevFavorite => {
                    if (prevFavorite === currentWord.word) {
                        return '';
                    } else {
                        return currentWord.word;
                    }
                });
                setMessage(`Successfully ${favorite === currentWord.word ? 'removed from' : 'added to'} favorites!`);
                setTimeout(() => setMessage(''), 500);
            } else {
                console.error('Failed to update favorite status');
                setMessage('Failed to update favorite status.');
            }
        } catch (error) {
            console.error('Error updating favorite status:', error);
            setMessage('Error updating favorite status.');
        }
    };

    const toggleSettings = () => {
        setShowSettings(prevShowSettings => !prevShowSettings);
    };

    const toggleMore = () => {
        setShowMore(prevShowMore => !prevShowMore);
    };

    const handleMouseMove = (event) => {
        const container = event.currentTarget;
        const rect = container.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        if (x > (rect.width * 7) / 8 && y < rect.height / 8) {
            setShowSettingsButton(true);
        } else {
            setShowSettingsButton(false);
        }

        if (x < (rect.width * 1) / 8 && y < rect.height / 8) {
            setShowMoreButton(true);
        } else {
            setShowMoreButton(false);
        }
    };

    const getResultClassName = () => {
        if (result === 'Loading...') {
            return 'loading';
        }
        return '';
    };

    const handleLibrarySelect = (event) => {
        setLibraryName(event.target.value);
        setLibraryConfirmed(true);
    };

    const handleCheckboxChange = (event) => {
        setIsReview(event.target.checked);
    };

    return (
        <div className="container" onMouseMove={handleMouseMove}>
            {showSettingsButton && (
                <div className="settings-button-container">
                    <button className="settings-button" onClick={toggleSettings}>
                        <img src="settings.png" alt="Settings" />
                    </button>
                </div>
            )}
            {showMoreButton && (
                <div className="more-button-container">
                    <button className="more-button" onClick={toggleMore}>
                        <img src="more.png" alt="More" />
                    </button>
                </div>
            )}
            <h1>Spelling words</h1>
            {!libraryConfirmed && (
                <div className="library-select-container">
                    <input type="checkbox" id="reviewFavorites" name="reviewFavorites" onChange={handleCheckboxChange} />
                    <label htmlFor="reviewFavorites">Review</label>
                    <select onChange={handleLibrarySelect}>
                        <option value="">Select a library</option>
                        {libraries.map((library) => (
                            <option key={library} value={library}>
                                {library}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            {libraryConfirmed && (
                <>
                    <div className="input-row">
                        <button
                            onClick={toggleFavorite}
                            className={favorite === currentWord.word ? 'favorite-button active' : 'favorite-button'}
                        >
                            <img src={favorite === currentWord.word ? "favorited.png" : "favorite.png"} alt="Favorite" className="favorite-icon" />
                        </button>
                        <input
                            type="text"
                            ref={inputRef}
                            placeholder="Type the word here"
                            value={userInput}
                            onChange={handleInputChange}
                        />
                        <button onClick={advanceToNextWord}>
                            <img src="next.png" alt="Next" className="favorite-icon" />
                        </button>
                    </div>
                    <div className="audio-container">
                        <span id="pronunciation">{currentWord.us_pron ? `[ ${currentWord.us_pron} ]` : ''}</span>
                        <img
                            src="play.png"
                            alt="Play"
                            className="play-icon"
                            onClick={playAudio}
                        />
                        <audio ref={audioRef} src={currentWord.us_audio}></audio>
                    </div>
                    <div className="row">
                        <span id="definition" dangerouslySetInnerHTML={{ __html: currentWord.definition }}></span>
                    </div>
                    <div className="row">
                        <span id="result" className={getResultClassName()}>{result}</span>
                    </div>
                </>
            )}
            {showSettings && (
                <SettingsModal
                    show={showSettings}
                    onClose={toggleSettings}
                />
            )}
            {showMore && (
                <ShowMore
                    show={showMore}
                    onClose={toggleMore}
                    word={currentWord.word}
                    library_name={libraryName}
                />
            )}
            {message && <div className="message-container">{message}</div>}
        </div>
    );
};

export default App;
