import React, { useState } from 'react';
import './ShowMore.css';
import Setting1 from './Setting1';
import Setting2 from './Setting2';

const ShowMore = ({ show, onClose }) => {
    const [activeTab, setActiveTab] = useState('setting1');

    if (!show) {
        return null;
    }

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>More</h2>
                <button className="close-button" onClick={onClose}>X</button>
                <div className="tabs">
                    <button
                        className={`tab-button ${activeTab === 'setting1' ? 'active' : ''}`}
                        onClick={() => handleTabChange('setting1')}
                    >
                        Setting1
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'setting2' ? 'active' : ''}`}
                        onClick={() => handleTabChange('setting2')}
                    >
                        Setting2
                    </button>
                </div>
                <div className="settings-content">
                    {activeTab === 'setting1' && <Setting1 />}
                    {activeTab === 'setting2' && <Setting2 />}
                </div>
            </div>
        </div>
    );
};

export default ShowMore;
